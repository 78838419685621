<template>
  <custom-button
      :attributes="meta.button"
      :content="field.label && field.label.name ? $t(field.label.name) : ''"
      @click.native="callFunction"
  ></custom-button>
</template>

<script>
import abstractForm from '../../mixin/index';
import button from '../../mixin/button';
import CustomButton from '../../UI/Button.vue';

export default {
  name: 'index',
  mixins: [abstractForm, button],
  components: {CustomButton},
  methods: {
    callFunction() {
      this[this.meta.function]();
    },
  },
};
</script>

<style scoped>

</style>
